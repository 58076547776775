import { createGlobalStyle } from 'styled-components'
import { alpha } from '@material-ui/core/styles'
import { theme } from './theme'
import { irem, rem } from './utils/rem'

export const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ul {
    list-style: none;
    padding-left: 0;
    display: block;

    li {
      position: relative;
      max-width: calc(100% - ${props => props.theme.spacing(4)});
      display: list-item;
      flex-direction: row;
      align-items: baseline;
      font-size: ${theme.typography.pxToRem(17)};
      color: ${theme.palette.text.secondary};
      line-height: 1.8;
      margin-bottom: ${theme.typography.pxToRem(13)};

      &::before {
        position: absolute;
        content: "•";
        display: inline-block;
        top: ${props => props.theme.typography.pxToRem(4)};
        color: ${theme.palette.secondary.main};
        padding-right: ${theme.typography.pxToRem(12)};
        font-size: ${theme.typography.pxToRem(26)};
        line-height: 1rem;
      }

      ${theme.breakpoints.up('xs')} {
        font-size: ${theme.typography.pxToRem(15)};
      }

      ${theme.breakpoints.up('sm')} {
        font-size: ${theme.typography.pxToRem(17)};
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .MuiPopover-paper {
    > ul > li {
      font-size: ${theme.typography.pxToRem(16)};
      color: ${props => props.theme.palette.text.secondary};

      &::before {
        display: none;
      }
    }
  }

  ol:not([class]) {
    display: block;
    list-style: none;
    counter-reset: list-counter;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      max-width: calc(100% - ${props => props.theme.spacing(4)});
      display: list-item;
      flex-direction: row;
      align-items: baseline;
      counter-increment: list-counter;
      font-size: ${theme.typography.pxToRem(17)};
      color: ${theme.palette.text.secondary};
      line-height: ${30/17};
      margin-bottom: 1em;

      &::before {
        position: absolute;
        content: counters(list-counter, ".") ".";
        display: inline-block;
        padding-right: ${theme.typography.pxToRem(15)};
        font-size: inherit;
        font-weight: 500;
      }

      ${theme.breakpoints.up('xs')} {
        font-size: ${theme.typography.pxToRem(15)};
      }

      ${theme.breakpoints.up('sm')} {
        font-size: ${theme.typography.pxToRem(17)};
      }
    }
  }

  ol:not([class]),
  ul:not([class]) {
    margin-block-end: ${props => props.theme.custom.spacing.paragraph};

    li {
      left: ${irem(26)};

      ${props => props.theme.breakpoints.up('sm')} {
        left: ${props => props.theme.spacing(4)};
      }

      &::before {
        left: ${irem(-26)};

        ${props => props.theme.breakpoints.up('sm')} {
          left: ${props => props.theme.spacing(-4)};
        }
      }
    }

    li > & {
      margin-top: 1em;

      li {
        left: ${props => props.theme.spacing(4)};

        &::before {
          left: ${props => props.theme.spacing(-4)};
        }
      }
    }
  }

  a {
    color: ${theme.palette.secondary.dark};
    text-decoration: underline;

    &:hover {
      color: ${theme.palette.secondary.main};
      cursor: pointer;
    }
  }

  hr {
    border: none;
    height: 3px;
    background: #e0e0e0;
    margin: 2.5em 0;
  }

  p,
  p.MuiTypography-body2 {
    margin-bottom: ${props => props.theme.custom.spacing.paragraph};
  }

  p:not([class]) + & {
    margin-top: ${irem(-25)};
  }

  blockquote {
    display: flex;
    flex-direction: column;
    background: ${alpha('#fff4dc', 0.7)};
    margin: 0;
    color: ${theme.palette.text.primary};
    padding-top: ${theme.typography.pxToRem(60)};
    padding-right: ${theme.typography.pxToRem(20)};
    padding-bottom: ${theme.typography.pxToRem(20)};
    padding-left: ${theme.typography.pxToRem(20)};
    font-weight: 400;
    line-height: 1.8;
    position: relative;
    max-width: ${props => rem(930, props.theme)};

    &::before {
      position: absolute;
      left: ${theme.typography.pxToRem(20)};
      top: ${theme.typography.pxToRem(25)};
      height: ${theme.typography.pxToRem(24)};
      width: ${theme.typography.pxToRem(24)};
      content: "";
      background: url('/images/quote.svg');
      background-size: contain;
      background-repeat: no-repeat;
      color: ${theme.palette.secondary.main};
      display: inline-block;
    }

    p {
      margin: 0;
      text-transform: uppercase;
      font-style: normal;
      font-weight: ${props => props.theme.typography.fontWeightMedium};
    }

    cite {
      margin-top: ${props => props.theme.typography.pxToRem(35)};
      color: ${theme.palette.text.primary};
      font-style: normal;
      font-weight: ${props => props.theme.typography.fontWeightMedium};
    }

    ${theme.breakpoints.up('sm')} {
      font-size: ${props => props.theme.typography.pxToRem(20)};
      line-height: ${props => props.theme.typography.pxToRem(36)};
      padding: ${props => rem(72, props.theme)} ${props => rem(60, props.theme)} ${props => rem(51, props.theme)} ${props => rem(115, props.theme)};

      &::before {
        top: ${props => rem(80, props.theme)};
        left: ${props => rem(57, props.theme)};
      }

      p {
        letter-spacing: ${props => rem(1.5, props.theme)};
        font-size: ${props => props.theme.typography.pxToRem(15)};
      }
    }
  }

  table {
    margin-bottom: 40px;

    th {
      tr {
        border-bottom: 2px solid ${props => props.theme.palette.secondary.light};
        background-color: ${props => alpha(props.theme.palette.secondary.light, 0.15)};
      }
    }
  }

  .MuiTableContainer-root {
    > table {
      thead > tr {
        th {
          text-align: left;
          border-bottom: 2px solid ${props => props.theme.palette.secondary.light};
          background-color: ${props => alpha(props.theme.palette.secondary.light, 0.15)};
        }

        th:not(.MuiTableCell-root) {
          padding: 16px;
          font-weight: 500;
        }
      }

      tbody > tr {
        th:not(.MuiTableCell-root),
        td:not(.MuiTableCell-root) {
          border-bottom: 1px solid rgba(224, 224, 224, 1);
          padding: 16px;
        }
      }
    }
  }

  .MuiTypography-h5 {
    font-weight: 500;
  }

  .MuiTypography-h1,
  h1,
  .MuiTypography-h2,
  h2,
  .MuiTypography-h3,
  h3,
  .MuiTypography-h4,
  h4,
  .MuiTypography-h5,
  h5,
  .MuiTypography-h6,
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }

  #__next {
    overflow-x: hidden;
  }

  .alert-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }

  #webchat_button {
    margin-right: 20px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    cursor: pointer;
    background: #517F34;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px -4px #000000;

    @media only screen and (max-width: 960px) {
      bottom: 100px;
    }

    @media only screen and (max-width: 600px) {
      bottom: 160px;
    }
  }

  #webchat_image {
    background-image: url("/images/Group_2842.svg");
    background-size: cover;
    padding: 14px;
  }

  #cisco_bubble_chat {
    z-index: 1002 !important;
  }
`
